<template>
  <CMBreadCrumb
    :children="[
      { text: 'Statistik', link: '/admin/stats' },
      { text: 'Funnel', link: '/stats/funnel' },
    ]"
  />
  <div>
    <p class="text-xs italic">
      Achtung: Gemeinschaftskonto informationen sind hier ausgeblendet
    </p>
    <div v-if="loaded" class="py-4">
      <Bar :data="data" :options="options" :height="420" />
    </div>
    <LoadingCircle v-else />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";
import shared from "@/shared";
import LoadingCircle from "@/components/LoadingCircle.vue";
import axios from "axios";
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default {
  name: "App",
  components: {
    Bar,
    CMBreadCrumb,
    LoadingCircle,
  },
  data() {
    return {
      loaded: false,
      data: {},
      options: {},
    };
  },
  created() {
    axios
      .get(process.env.VUE_APP_BACKEND_DOMAIN + "funnel-api.php", {
        headers: {
          "x-access-token": this.$store.getters.getJwt,
        },
      })
      .then((res) => {
        console.log(res);
        this.data = {
          labels: shared.getStepsArray().slice(0, 5),
          datasets: [
            {
              label: "Abschluss Zahl",
              backgroundColor: "#424242",
              data: [
                res.data.step1count,
                res.data.step2count,
                res.data.step3count,
                res.data.step4count,
                res.data.step5count,
                res.data.step6count,
              ],
            },
          ],
        };

        this.options = {
          responsive: true,
          maintainAspectRatio: false,
        };

        this.loaded = true;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
