<template>
  <div class="mb-4">
    <fieldset v-if="fieldsets && loaded" :class="fieldsetClass(0)">
      <legend class="font-bold">
        Hintergrund der Geschäftsbeziehungen bzw. Inland-Bezug (Mehrfachauswahl)
      </legend>
      <p class="text-sm italic">
        Bitte wählen Sie zumindest eine der folgenden Optionen:
      </p>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check1"
          v-model="fieldsets.dienstort_inland"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check1'"
          >Dienstort im Inland / Selbstständige wirtschaftliche Tätigkeit im Inland</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check2"
          v-model="fieldsets.wohnsitzaenderung"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check2'"
          >Wohnsitz(-änderung)</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check3"
          v-model="fieldsets.immobilienerwerb"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check3'"
          >Immobilienerwerb</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check4"
          v-model="fieldsets.vermittlung"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check4'"
          >Vermittlung/Empfehlung durch bestehenden Kunden:-/-</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check5"
          v-model="fieldsets.finanzplatz_austria"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check5'"
          >Österreich als sicherer Finanzplatz
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="langfristige_veranlagung_inland"
          v-model="fieldsets.langfristige_veranlagung_inland"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'langfristige_veranlagung_inland'"
          >Längerfristige Veranlagung im Inland
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="check6"
          v-model="fieldsets.sonstige_1"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'check6'"
          >Sonstiges
        </label>
      </div>
      <div v-if="fieldsets.sonstige_1">
        <label class="block text-sm font-bold mb-2" :for="forPrefix + 'sonstige_1_text'"
          >Sonsitges (Erkärung):</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="sonstige_1_text"
          v-model="fieldsets.sonstige_1_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          placeholder="Beschreibung"
        />
      </div>
    </fieldset>
    <fieldset :class="fieldsetClass(1)">
      <p class="text-sm italic">
        Bitte wählen Sie zumindest eine der folgenden Optionen:
      </p>
      <legend class="font-bold">
        Herkunft der aktuellen/künftigen Vermögenswerte (Mehrfachauswahl)
      </legend>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="einkommen"
          v-model="fieldsets.einkommen_unselbststaendig"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'einkommen'"
          >Laufendes Einkommen aus unselbstständiger Tätigkeit / Unterhalt (z.B.: Lohn /
          Gehahlt)</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="freelance_einkommen"
          v-model="fieldsets.einkommen_freiberuf"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'freelance_einkommen'"
          >Laufendes Einkommen aus freiberuflicher / selbstständiger Tätigkeit</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="dividende_etc"
          v-model="fieldsets.dividende_etc"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'dividende_etc'"
          >Erträge / Dividenden / Mieteinnahmen
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="erbschaft_schenkung"
          v-model="fieldsets.erbschaft_schenkung"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'erbschaft_schenkung'"
          >Erbschaft / Schenkung
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="verkauf_vermoegenswerte"
          v-model="fieldsets.verkauf_vermoegenswerte"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'verkauf_vermoegenswerte'"
        >
          Verkauf von Vermögenswerten (z.B.: Immobilien, Wertpapiere,
          Unternehmensbeteiligungen)
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="ablauf_von_vertraegen"
          v-model="fieldsets.ablauf_von_vertraegen"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'ablauf_von_vertraegen'">
          Ablauf von Versicherungen und Verträgen (z.B.: Lebensversicherung,
          Bausparverträge)
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="sonstige_2"
          v-model="fieldsets.sonstige_2"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'sonstige_2'">
          Sonstiges
        </label>
      </div>
      <div v-if="fieldsets.sonstige_2">
        <label class="block text-sm font-bold mb-2" :for="forPrefix + 'sonstige_2_text'"
          >Sonsitges (Erkärung):</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="sonstige_2_text"
          v-model="fieldsets.sonstige_2_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          placeholder="Beschreibung"
        />
      </div>
    </fieldset>
    <fieldset v-if="$route.query.country == 'at'" :class="fieldsetClass(2)">
      <p class="text-sm italic">
        Bitte wählen Sie zumindest eine der folgenden Optionen:
      </p>
      <legend class="font-bold">
        Geplanter Verwendungszweck des Vermögens (Mehrfachauswahl)
      </legend>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="lebensunterhalt"
          v-model="fieldsets.lebensunterhalt"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'lebensunterhalt'">
          Lebensunterhalt
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="schenkung"
          v-model="fieldsets.schenkung"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'schenkung'">
          Unterstützung im Familienkreis (inkl. Schenkung)
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="veranlagung_finanzprodukte"
          v-model="fieldsets.veranlagung_finanzprodukte"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'veranlagung_finanzprodukte'"
        >
          Veranlagung in Finanzprodukte
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="immobilienerwerb"
          v-model="fieldsets.immobilienerwerb_2"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'immobilienerwerb'">
          Immobilienerwerb
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="veranlagung_unternehmensbeteiligungen"
          v-model="fieldsets.veranlagung_unternehmensbeteiligungen"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'veranlagung_unternehmensbeteiligungen'"
        >
          Veranlagung in Unternehmensbeteiligungen
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="sonstige_4"
          v-model="fieldsets.sonstige_4"
          :disabled="disabled"
          type="checkbox"
        />
        <label class="block text-sm py-3 px-2" :for="forPrefix + 'sonstige_4'">
          Sonstiges
        </label>
      </div>
      <div v-if="fieldsets.sonstige_4">
        <label class="block text-sm font-bold mb-2" :for="forPrefix + 'sonstige_4_text'"
          >Sonsitges (Erkärung):</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="sonstige_4_text"
          v-model="fieldsets.sonstige_4_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          placeholder="Beschreibung"
        />
      </div>
    </fieldset>
    <fieldset class="mb-2">
      <legend class="font-bold">Weitere</legend>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          id="weitere_veranlagungen_geplant"
          v-model="fieldsets.weitere_veranlagungen_geplant"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'weitere_veranlagungen_geplant'"
        >
          Ich plane noch weitere Veranlagungen bei der VakifBank International AG
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      fieldsets: {
        // fieldset 0
        dienstort_inland: false,
        wohnsitzaenderung: false,
        immobilienerwerb: false,
        vermittlung: false,
        finanzplatz_austria: false,
        langfristige_veranlagung_inland: false,
        sonstige_1: false,
        sonstige_1_text: "",

        // fieldset 1
        einkommen_unselbststaendig: false,
        einkommen_freiberuf: false,
        dividende_etc: false,
        erbschaft_schenkung: false,
        verkauf_vermoegenswerte: false,
        ablauf_von_vertraegen: false,
        sonstige_2: false,
        sonstige_2_text: "",

        // fieldset 2
        lohn_gehaltszettel: false,
        einkommenssteuererklaerung: false,
        depotauszug: false,
        erbschaft: false,
        sonstige_vertraege: false,
        sonstige_3: false,
        sonstige_3_text: "",

        // fieldset 3
        lebensunterhalt: false,
        schenkung: false,
        veranlagung_finanzprodukte: false,
        immobilienerwerb_2: false,
        veranlagung_unternehmensbeteiligungen: false,
        sonstige_4: false,
        sonstige_4_text: "",

        // fieldset
        weitere_veranlagungen_geplant: false,
      },
    };
  },
  methods: {
    fieldsetClass(n) {
      let fieldsetClass = "mb-2 border p-3 rounded-lg";
      if (this.showErrorMessage && !this.fieldsetsOkay[n]) {
        fieldsetClass = fieldsetClass + " bg-red-100";
      }

      return fieldsetClass;
    },
  },
  props: {
    proxyFieldset: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // due to some bug we need to change the for field so it does not match the id
    // of the input element to prevent a toggle...
    forPrefix() {
      if (this.disabled) {
        return "_";
      }
      return "";
    },
    fieldsetsOkay() {
      let returnObject = [false, false, false, false];

      if (
        this.fieldsets.dienstort_inland ||
        this.fieldsets.wohnsitzaenderung ||
        this.fieldsets.immobilienerwerb ||
        this.fieldsets.vermittlung ||
        this.fieldsets.finanzplatz_austria ||
        this.fieldsets.langfristige_veranlagung_inland ||
        this.fieldsets.sonstige_1
      ) {
        returnObject[0] = true;
      }

      if (
        this.fieldsets.einkommen_unselbststaendig ||
        this.fieldsets.einkommen_freiberuf ||
        this.fieldsets.dividende_etc ||
        this.fieldsets.erbschaft_schenkung ||
        this.fieldsets.verkauf_vermoegenswerte ||
        this.fieldsets.ablauf_von_vertraegen ||
        this.fieldsets.sonstige_2
      ) {
        returnObject[1] = true;
      }

      /**
       * NOTICE: IF NEEDED TO GET "Nachweis der Einkünfte / Vermögen - Beibringen von Dokumenten (Mehrfachauswahl)" back,
       * look at the github commits before 23.03.23 :)
       */
      if (
        this.fieldsets.lebensunterhalt ||
        this.fieldsets.schenkung ||
        this.fieldsets.veranlagung_finanzprodukte ||
        this.fieldsets.immobilienerwerb_2 ||
        this.fieldsets.veranlagung_unternehmensbeteiligungen ||
        this.fieldsets.sonstige_4
      ) {
        returnObject[2] = true;
      }

      return returnObject;
    },
  },
  created() {
    if (this.proxyFieldset && this.disabled) {
      this.fieldsets = this.proxyFieldset;
    }
    this.loaded = true;
  },
  watch: {
    fieldsets: {
      handler(newF) {
        this.$emit("update", newF);
      },
      deep: true,
    },
  },
};
</script>
