import { toRaw } from "vue";

export default {
    getStartingState() {
        return {
            jwt: "",
            persons: [{}, {}],
            fieldsets: {},
            bankInfo: {},
            responses: [],
            applications: [],
            lastApplicationGuid: "unspecified",
            applicationsFilter: {
                filter: "1",
                search: "",
                mode: "more",
                sortMode: "desc",
                sortType: "date",
            }
        };
    },
    removeObjectWithId(arr, guid) {
        const objWithIdIndex = this.getObjectViaId(arr, guid);

        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }
        return arr;
    },
    // todo make update object via id function
    getObjectViaId(arr, guid) {
        arr.forEach(obj => {
            if (obj.guid == guid) {
                console.log(toRaw(obj));
                return toRaw(obj);
            }
        });
    },
    getStepsArray() {
        return [
            "1 - Anlagedauer & Betrag",
            "2 - Persönliche Informationen",
            "3 - Kontoinformationen",
            "4 - Fragebogen",
            "5 - Formular Abschluss",
            "6 - CRIF begonnen",
            "7 - CRIF Identifikation abgeschlossen",
            "8 - CRIF Signatur abgeschlossen",
        ];
    },
    beautifyDate(date, dateOnly = false, timeOnly = false) {
        if (!(date instanceof Date)) {
            console.warn("Wrong INPUT:", date);
            return undefined;
        }

        if (dateOnly) {
            return date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear();
        }

        if (timeOnly) {
            let minutes = date.getMinutes();
            if (minutes == 0) {
                minutes = "00";
            }
            return date.getHours() + ":" + minutes;
        }

        return (
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear() +
            " " +
            date.toLocaleTimeString().substring(0, 5)
        );
    },
    monthsToString(months) {
        months = Number(months);
        console.log("Monate:", months);
        if (months < 12) {
            return months + " Monate";
        }
        else {
            if (months % 12 == 0) {
                if (months / 12 == 1) {
                    return "1 Jahr";
                }
                return Math.floor(months / 12) + " Jahre";
            }
            if (months / 12 == 1) {
                if (months % 12 == 1) {
                    return Math.floor(months / 12) + " Jahr " + months % 12 + " Monat";
                }
                return Math.floor(months / 12) + " Jahr " + months % 12 + " Monate";
            }
            if (months % 12 == 1) {
                return Math.floor(months / 12) + " Jahre " + months % 12 + " Monat";
            }
            return Math.floor(months / 12) + " Jahre " + months % 12 + " Monate";
        }
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
}