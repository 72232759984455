<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'sex' + id">Anrede:</label>
        <select
          :id="'sex' + id"
          class="select w-full shadow border border-gray-200"
          required
          aria-required="true"
          :disabled="disabled"
          v-model="person.sex"
        >
          <option disabled selected>Bitte wählen Sie eine Anrede</option>
          <option value="mann">Herr</option>
          <option value="frau">Frau</option>
        </select>
      </div>
      <div class="ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'title' + id">Titel:</label>
        <select
          :id="'title' + id"
          class="select w-full shadow border border-gray-200"
          v-model="person.title"
          :disabled="disabled"
        >
          <option disabled selected>Bitte auswählen</option>
          <option value="mag">Mag.</option>
          <option value="dr">Dr.</option>
          <option value="ing">Ing.</option>
          <option value="di">DI.</option>
          <option value="ingmag">Ing. Mag.</option>
          <option value="dir">Dir.</option>
          <option value="univprof">Univ. Prof.</option>
          <option value="prof">Prof.</option>
          <option value="dipling">Dipl.- Ing. (HTL)</option>
          <option value="diplpad">Dipl.- Päd.</option>
          <option value="mmagdr">MMag. Dr.</option>
        </select>
      </div>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'firstName' + id">Vorname:</label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'firstName' + id"
        v-model="person.firstName"
        type="text"
        :disabled="disabled"
        required
        placeholder="Vorname"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'lastName' + id">Nachname:</label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'lastName' + id"
        v-model="person.lastName"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        placeholder="Nachname "
      />
    </div>
    <div class="flex flex-col sm:flex-row mb-4">
      <div class="flex-1 mb-4 sm:mb-0 sm:mr-1">
        <label class="block text-sm font-bold mb-2" :for="'plz' + id">PLZ:</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'plz' + id"
          v-model="person.plz"
          aria-required="true"
          type="text"
          required
          :disabled="disabled"
          placeholder="PLZ "
        />
      </div>
      <div class="flex-1 mb-4 sm:mb-0 sm:ml-1">
        <label class="block text-sm font-bold mb-2" :for="'city' + id">Ort:</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'city' + id"
          v-model="person.city"
          type="text"
          aria-required="true"
          :disabled="disabled"
          required
          placeholder="Ort "
        />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mb-2">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'street' + id">Straße:</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'street' + id"
          v-model="person.street"
          required
          :disabled="disabled"
          type="text"
          placeholder="Straße"
        />
      </div>
      <div class="mb-4 sm:mb-0 sm:ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'streetNo' + id"
          >Hausnummer:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'streetNo' + id"
          v-model="person.streetNo"
          required
          aria-required="true"
          :disabled="disabled"
          type="text"
          placeholder="Hausnr."
        />
      </div>
    </div>
    <div class="mb-4">
      <CountryDropdown
        v-model="person.country"
        :disabled="disabled"
        :value-to-select="person.country"
      />
      <p
        v-if="
          person.country != this.$route.query.country.toUpperCase() &&
          person.country != undefined &&
          person.country != ''
        "
        class="text-red-500 p-2"
      >
        Sie haben nicht {{ countryMap[$route.query.country.toLowerCase()] }} als Land
        gewählt, bitte
        <a
          class="font-bold"
          href="https://vakifbank.at/de/kontakt"
          target="_blank"
          rel="noopener noreferrer"
          >kontaktieren Sie uns</a
        >
        um Ihren Account zu erstellen. Sie können über dieses Online Formular nicht
        fortfahren.
      </p>
    </div>
    <fieldset class="border my-2 px-2 rounded-lg shadow">
      <legend class="font-bold mx-1 p-2">Bitte falls zutreffend ankreuzen:</legend>
      <div>
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check00' + id"
            :disabled="disabled"
            v-model="person.us_citizen"
            type="checkbox"
          />
          <label class="block text-sm p-4" :for="'check00' + id"
            >Sind Sie U.S. Staatsbürger?</label
          >
        </div>
        <p class="text-xs" v-if="person.us_citizen">
          🇺🇸 Sie müssen zusätzlich das
          <a
            href="https://www.irs.gov/forms-pubs/about-form-w-9"
            target="__blank"
            class="underline"
            >IRS-Formular W-9</a
          >
          und einen Consent Report ausfüllen.
        </p>
      </div>
      <div class="flex align-middle items-center" v-if="person.us_citizen">
        <input
          class="checkbox"
          :id="'us_citizen_rule' + id"
          v-model="person.us_citizen_rule"
          type="checkbox"
          required
          :disabled="disabled"
          aria-required="true"
        />
        <label class="block text-sm p-4" :for="'us_citizen_rule' + id">
          🇺🇸 <strong>Für Bürger der USA und Inhaber von Green Cards:</strong> Ich besitze
          eine Arbeits- / Aufenthaltserlaubnis in den USA (Green Card) oder bin US-Bürger.
          Im Sinne der Bestimmungen des Foreign Account Tax Compliance Act (FATCA)
          entbinde ich die VakifBank International AG von den
          Verschwiegenheitsbestimmungen des österreichischen Bankgeheimnisses. Gemaess dem
          Foreign Account Tax Compliance Act (FATCA) hat die VakifBank mit IRS, Internal
          Revenue Service (US Steuerbehoerde) ein Abkommen abgeschlossen. Die VakifBank
          International AG darf Personen-, Konto- und Transaktionsdaten im Sinne von FATCA
          an die zustaendigen US-Behoerden weitergeben. Ein Widerruf dieser Ermaechtigung
          führt zur Kündigung der Kontoverbindung durch die VakifBank International
          AG.</label
        >
      </div>
      <div v-if="this.$route.query.country == 'at'">
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check01' + id"
            :disabled="disabled"
            v-model="person.extra_rule_austria"
            type="checkbox"
          />
          <label class="block text-sm p-4" :for="'check01' + id"
            >Hiermit bestätige ich, dass ich keinen Wohnsitz bzw. außergewöhnlichen
            Aufenthalt iSd § 26 BAO in Österreich habe.</label
          >
        </div>
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check02' + id"
            v-model="person.zweitwohnsitz"
            type="checkbox"
            :disabled="disabled"
          />
          <label class="block text-sm p-4" :for="'check02' + id"
            >Hiermit bestätige ich, dass ich einen Zweitwohnsitz iSd § 1
            Zweitsitzverordnung in Österreich habe - bitte füllen Sie zusätzlich das
            Zweitwohnsitzerklärungsformular aus.</label
          >
        </div>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check044' + id"
          v-model="person.pep"
          type="checkbox"
          :disabled="disabled"
        />
        <label class="block text-sm p-4" :for="'check044' + id"
          >Sind Sie eine eine politisch exponierte Person - PEP bzw. eine naehestehende
          Familienangehörige Person PEP?</label
        >
      </div>
      <div v-if="person.pep">
        <label class="block text-sm font-bold mb-2 p-2" for="pep_text"
          >Genaue Beschreibung der Funktion als PEP:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="pep_text"
          v-model="person.pep_text"
          type="text"
          required
          aria-required="true"
          placeholder="Beschreibung"
          :disabled="disabled"
        />
      </div>
      <div v-if="$route.query.country == 'de'" class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'anderesSteuerLand' + id"
          v-model="person.steuerpflichtigAusland"
          type="checkbox"
          :disabled="disabled"
        />
        <label class="block text-sm p-4" :for="'anderesSteuerLand' + id"
          >Sind Sie in einem anderen Land steuerpflichtig?</label
        >
      </div>
      <SteuerpflichtigeCountries
        v-if="person.steuerpflichtigAusland"
        v-model="person.steuerInfo"
        :disabled="disabled"
        :initial-info="person.steuerInfo"
      />
    </fieldset>
    <div class="flex flex-col mb-4" v-if="$route.query.country == 'de'">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'steuernummer' + id"
          >Steuernummer:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'steuernummer' + id"
          v-model="person.steuernummer"
          required
          :disabled="disabled"
          aria-required="true"
          minlength="11"
          maxlength="11"
        />
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'kontohaber_ok' + id"
          :disabled="disabled"
          v-model="person.crs_ok"
          type="checkbox"
          required
        />
        <label class="block text-sm p-4" :for="'kontohaber_ok' + id"
          >Der Kontoinhaber versichert, dass alle oben gemachten Angaben über die
          steuerliche(n) Ansässigkeit(en) wahrheitsgemäß und vollständig sind und
          verpflichtet sich hiermit, etwaige Änderungen dieser Angaben der Bank gegenüber
          innerhalb von 30 Tagen anzuzeigen</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'kirchensteuerverfahren_input' + id"
          :disabled="disabled"
          v-model="person.kirchensteuerverfahren_ok"
          type="checkbox"
          required
        />
        <label class="block text-sm p-4" :for="'kirchensteuerverfahren_input' + id"
          >Ich habe die
          <a href="/kirchensteuerverfahren" class="underline" target="_blank"
            >Hinweise über das Kirchensteuerverfahren</a
          >
          gelesen und akzeptiere.</label
        >
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mb-4">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'bday' + id">Geburtstag:</label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'bday' + id"
          v-model="person.birthday"
          required
          :disabled="disabled"
          aria-required="true"
          type="date"
        />
      </div>
      <div class="mb-4 sm:mb-0 sm:ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'bdaycity' + id"
          >Geburtsort:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'bdaycity' + id"
          v-model="person.birthCity"
          required
          aria-required="true"
          type="text"
          :disabled="disabled"
          placeholder="Ort der Geburt"
        />
      </div>
    </div>
    <div class="mb-4">
      <CountryDropdown
        label="Nationalität"
        v-model="person.nationality"
        :disabled="disabled"
        :value-to-select="person.nationality"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'famstand' + id"
        >Familienstand:</label
      >
      <select
        :id="'famstand' + id"
        class="select w-full shadow border border-gray-200"
        required
        aria-required="true"
        v-model="person.familienStand"
        :disabled="disabled"
      >
        <option disabled selected>Bitte auswählen:</option>
        <option value="ledig">Ledig</option>
        <option value="partnerschaft">Partnerschaft</option>
        <option value="verheiratet">Verheiratet</option>
        <option value="geschieden">Geschieden</option>
        <option value="verwitwet">Verwitwet</option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'works' + id"
        >Beschäftigung:</label
      >
      <select
        :id="'works' + id"
        class="select w-full shadow border border-gray-200"
        required
        aria-required="true"
        :disabled="disabled"
        v-model="person.beschaeftigung"
      >
        <option disabled selected>Bitte auswählen:</option>
        <option value="angestellter">Angestellter</option>
        <option value="arbeiter">Arbeiter</option>
        <option value="beamter">Beamter</option>
        <option value="beschaeftigungslos">Beschäftigungslos</option>
        <option value="hausfraumann" v-if="person.sex == 'frau'">Hausfrau</option>
        <option value="hausfraumann" v-else>Hausmann</option>
        <option value="pensionist">Pensionist</option>
        <option value="student">Schüler / Student</option>
        <option value="selfemployed">Selbstständig</option>
      </select>
    </div>
    <h2 class="font-bold mb-4">Kontakt</h2>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'telNr' + id"
        >Telefonnummer:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'telNr' + id"
        v-model="person.telephone"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        placeholder="Telefonnummer im Internationalen Format (+49...)"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'email' + id">E-Mail:</label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'email' + id"
        v-model="person.email"
        required
        type="email"
        aria-required="true"
        :disabled="disabled"
        placeholder="E-mail "
      />
    </div>
    <h2 class="font-bold mb-4">Legitimation</h2>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisTyp' + id"
        >Lichtbildausweis:</label
      >
      <select
        :id="'ausweisTyp' + id"
        class="select w-full shadow border border-gray-200"
        required
        aria-required="true"
        v-model="person.ausweisTyp"
        :disabled="disabled"
      >
        <option disabled selected>Bitte auswählen:</option>
        <option value="pass">Reisepass</option>
        <option value="perso">Personalausweis</option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisNr' + id">Nummer:</label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisNr' + id"
        v-model="person.ausweisNummer"
        required
        type="text"
        aria-required="true"
        :disabled="disabled"
        placeholder="Ausweisnummer "
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisAgency' + id"
        >Behörde:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisAgency' + id"
        v-model="person.ausweisAgency"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        placeholder="Ausstellungsbehörde"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisDate' + id"
        >Ausstellungsdatum:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisDate' + id"
        v-model="person.ausweisDatum"
        required
        :disabled="disabled"
        type="date"
        aria-required="true"
        placeholder="Ausstellungsbehörde"
      />
    </div>
  </div>
</template>

<script>
import CountryDropdown from "@/components/CountryDropdown.vue";
import { toRaw } from "vue";
import SteuerpflichtigeCountries from "./SteuerpflichtigeCountries.vue";

export default {
  components: {
    CountryDropdown,
    SteuerpflichtigeCountries,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    proxyPerson: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      person: {
        extra_rule_austria: false,
        austria_exception: false,
        us_citizen: false,
        us_citizen_rule: false,
        zweitwohnsitz: false,
        pep: false,
        pep_text: "",
        sex: "",
        title: "",
        firstName: "",
        lastName: "",
        plz: "",
        city: "",
        street: "",
        country: null,
        streetNo: "",
        birthday: "1990-01-01",
        birthCity: "",
        nationality: "",
        familienStand: "",
        beschaeftigung: "",
        telephone: "",
        email: "",
        ausweisTyp: "",
        ausweisNummer: "",
        ausweisAgency: "",
        ausweisDatum: "2015-01-01",
        anderesSteuerLand: "",
        steuerInfo: [],
        steuerpflichtigAusland: false,
        steuernummer: "Meine Steuernummer (TEST)",
        crs_ok: false,
        kirchensteuerverfahren_ok: false,
      },
      countryMap: {
        de: "Deutschland",
        at: "Österreich",
      },
    };
  },
  created() {
    if (this.proxyPerson) {
      console.log(toRaw(this.proxyPerson));
      this.person = toRaw(this.proxyPerson);
    }
  },
  watch: {
    person: {
      handler(newP) {
        if (!this.proxyPerson) {
          console.log("Person changed");
          if (newP.firstName == "test") {
            this.fillDemoData();
          }

          if (String(newP.country).toLowerCase() != this.$route.query.country) {
            if (newP.country == "DE" || newP.country == "AT") {
              if (
                window.confirm(
                  `Mit einem Wohnsitz in ${
                    this.countryMap[newP.country.toLowerCase()]
                  } können Sie sich ein Konto bei Vakifbank ${
                    this.countryMap[newP.country.toLowerCase()]
                  } erstellen. Wollen Sie, dass wir Sie weiterleiten?`
                )
              ) {
                this.$router.push(
                  "/konto-erstellen?country=" + newP.country.toLowerCase()
                );
              } else {
                this.person.country = "";
              }
            }
          }
          this.$emit("update", newP);
        }
      },
      deep: true,
    },
  },
  methods: {
    fillDemoData() {
      if (this.id == "1") {
        this.person.firstName = "Test";
        this.person.lastName = "Test";
        this.person.sex = "mann";
        this.person.plz = "1010";
        this.person.city = "Wien";
        this.person.street = "Kärntnerstraße";
        this.person.streetNo = "1";
        this.person.birthCity = "Wien";
        this.person.nationality = this.$route.query.country.toUpperCase();
        this.person.familienStand = "ledig";
        this.person.beschaeftigung = "arbeiter";
        this.person.telephone = "069912343233";
        this.person.email = "test@mail.com";
        this.person.ausweisTyp = "pass";
        this.person.country = this.$route.query.country.toUpperCase();
        this.person.ausweisNummer = "Test Nummer";
        this.person.ausweisAgency = "Test Behörde";
      } else if (this.id == "2") {
        this.person.firstName = "Max";
        this.person.lastName = "Musterman";
        this.person.sex = "mann";
        this.person.plz = "1200";
        this.person.city = "Wien";
        this.person.street = "Wallensteinstraße";
        this.person.streetNo = "15";
        this.person.birthCity = "Wien";
        this.person.nationality = this.$route.query.country.toUpperCase();
        this.person.familienStand = "ledig";
        this.person.beschaeftigung = "arbeiter";
        this.person.telephone = "069914227743";
        this.person.email = "super@mario.com";
        this.person.ausweisTyp = "pass";
        this.person.country = this.$route.query.country.toUpperCase();
        this.person.ausweisNummer = "Ausweisnummer1234";
        this.person.ausweisAgency = "Behörde aller Behörden";
      }
      if (this.$route.query.country.toUpperCase() == "DE") {
        this.person.steuernummer = "Meine Steuernummer (TEST)";
        this.person.crs_ok = true;
      }
    },
  },
};
</script>
