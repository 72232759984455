<template>
  <div>
    <div v-if="missingGuid">Techinscher Fehler. GUID fehlt.</div>
    <div v-else-if="zinsSetting" class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Zinssatz #{{ zinsSetting.id }}
          </h1>
          <p class="mt-2 text-sm text-gray-700">Alle Regeln dieses Zinssatzes:</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            @click="showNewRule = !showNewRule"
            class="block rounded-md bg-vakifbank px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Regeln speichern
          </button>
        </div>
      </div>
      <section>
        <CustomModal :show="showNewRule">
          <form
            @submit="commitModalForm"
            @submit.prevent="onSubmit"
            id="zinsRuleCreation"
            class="p-8"
          >
            <h4 class="bg-orange-500 text-white font-bold p-2 rounded">
              Achtung: Zinssatz wird aktualisiert
            </h4>
            <CMInput
              type="toggle"
              v-model="modifyableActiveState"
              label="Soll dieser Zinssatz aktiv sein?"
            />
            <p>
              Bitte vorsichtig alles überprüfen und auch nach der Veröffentlichung einmal
              alles genau überprüfen mithilfe des Ertragsrechners.
            </p>
            <button
              type="submit"
              class="block mt-4 rounded-md bg-vakifbank px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Speichern
            </button>
          </form>
        </CustomModal>
      </section>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Min. Laufzeit (in Monaten)
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Max. Laufzeit (in Monaten)
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Zinssatz (in %)
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="(zR, i) in reactiveZRs"
                  :key="(zR, i)"
                  class="transition-all"
                  :class="toBeDelId == zR.uuid ? 'bg-red-200' : ''"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {{ i + 1 }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {{ zR.minMonths }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ zR.maxMonths }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ zR.zinsSatz }}
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <button
                      @click="removeZinsRule(zR.uuid)"
                      class="text-vakifbank hover:underline"
                    >
                      Löschen<span class="sr-only">Zeile löschen</span>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    {{ reactiveZRs.length + 1 }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                  >
                    <input
                      class="rounded"
                      type="number"
                      v-model="minMonths"
                      :min="newRuleMinMonth"
                      max="500"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input
                      class="rounded"
                      type="number"
                      v-model="maxMonths"
                      :min="newRuleMinMonth"
                      max="500"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input
                      class="rounded"
                      type="number"
                      v-model="zinsSatz"
                      min="0"
                      max="5"
                      step="0.01"
                    />
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <button @click="addZinsRule" class="text-vakifbank hover:underline">
                      Hinzufügen<span class="sr-only">Zeile hinzufügen</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <section class="bg-red-50 p-2 rounded-lg mt-24">
              <h4 class="underline text-lg font-bold mb-2">
                Im Ertragsrechner diese Zinsen vor aktiv-setzen testen:
              </h4>
              <p class="my-2">
                Modus: {{ zinsSetting.mode }}<br />Land: {{ zinsSetting.country }}
              </p>
              <iframe
                title="Ertragsrechner Testen"
                :src="`/ertragsrechner?specificID=${zinsSetting.id}&mode=${zinsSetting.mode}&hideNav=1`"
                class="w-full border-2 rounded"
                height="500"
              ></iframe>
              <p>
                <a
                  :href="`/ertragsrechner?specificID=${zinsSetting.id}&mode=${zinsSetting.mode}`"
                  class="underline"
                  target="_blank"
                  >Hier klicken um im Fullscreen zu testen</a
                >
              </p>
            </section>
          </div>
        </div>
      </div>
      <div class="my-4">
        Vorschau als Tabelle (... wie der Kunde sie auf der Webseite eingebettet sieht.)
        Um diese Ansicht zu aktualisieren, bitte Seite neu-laden.
        <iframe
          title="Zinsen Tabelle"
          :src="`/zinstable?mode=${zinsSetting.mode}&country=${zinsSetting.country}`"
          class="w-full border-2 rounded my-4"
          height="500"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CustomModal from "@/components/CustomModal.vue";
import shared from "@/shared";
import { v4 as uuidv4 } from "uuid";
import CMInput from "@/components/CMInput.vue";

export default {
  components: {
    CustomModal,
    CMInput,
  },
  data() {
    return {
      missingGuid: false,
      showNewRule: false,
      newRuleMinMonth: 1,

      //
      modifyableActiveState: false,

      // for fx
      toBeDelId: -1,

      // for zR
      minMonths: 1,
      maxMonths: 1,
      zinsSatz: 1,

      // ...
      neueZinsRule: {},
      zinsSetting: {},
      zinsRules: [],
      reactiveZRs: [], // displaying the zinsRules directly works, but a delete won't render ...
    };
  },
  watch: {
    zinsRules: {
      handler(nZRs) {
        this.reactiveZRs = [];

        let tmpZRs = [];
        nZRs.forEach((nZR) => {
          tmpZRs.push(nZR);
        });

        tmpZRs = tmpZRs.sort((obj1, obj2) => {
          return Number(obj1.minMonths) < Number(obj2.minMonths);
        });

        this.reactiveZRs = tmpZRs;
      },
      deep: true,
    },
  },
  methods: {
    commitModalForm() {
      this.sendZRs();
      this.sendNewActiveState();
    },
    async sendNewActiveState() {
      axios
        .patch(
          process.env.VUE_APP_ADMIN_BACKEND +
            "zinsSetting/" +
            this.zinsSetting.id +
            "/active",
          {
            newActiveState: this.modifyableActiveState,
          },
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: e,
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
    checkZinsRule(pZR) {
      if (pZR.minMonths > pZR.maxMonths) {
        throw new Error(
          "Fehler bei der Regel - Min. Monat kann nicht kleiner sein als Max. Monat"
        );
      }
      if (this.zinsRules.length > 0) {
        // make some checks to help zinssatz creation

        this.reactiveZRs.forEach((zR) => {
          if (pZR.minMonths >= zR.minMonths && pZR.minMonths < zR.maxMonths) {
            throw new Error("Fehler bei Min. Monat Einstellung - Überschneidende Regeln");
          }

          if (pZR.maxMonths > zR.minMonths && pZR.maxMonths <= zR.maxMonths) {
            throw new Error("Fehler bei Max. Monat Einstellung - Überschneidende Regeln");
          }
        });
      }
    },
    async removeZinsRule(uuid) {
      this.toBeDelId = uuid;

      await shared.sleep(300);
      this.zinsRules = this.zinsRules.filter((zR) => zR.uuid != uuid);

      this.toBeDelId = -1;
    },
    addZinsRule() {
      try {
        const tmpZinsRule = {
          minMonths: Number(this.minMonths),
          maxMonths: Number(this.maxMonths),
          zinsSatz: Number(this.zinsSatz),
          uuid: uuidv4(),
        };

        this.checkZinsRule(tmpZinsRule);

        this.zinsRules.push(tmpZinsRule);
        this.newRuleMinMonth = this.maxMonths + 1;

        // dispatch success message
        this.$store.dispatch("newResponse", {
          message: "Erfolgreich Zinsregel lokal erstellt",
          success: true,
        });

        // set values to facilitate creation
        this.minMonths = this.maxMonths;
        this.maxMonths++;
      } catch (e) {
        this.$store.dispatch("newResponse", {
          message: e,
          success: false,
          time: 12000,
        });
      }
    },
    async sendZRs() {
      axios
        .put(
          process.env.VUE_APP_ADMIN_BACKEND +
            "zinsSetting/" +
            this.zinsSetting.id +
            "/zinsRule",
          {
            zinsRules: this.reactiveZRs,
          },
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich gespeichert",
            success: true,
          });

          this.showNewRule = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: e,
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
  created() {
    if (!this.$route.params.guid) {
      this.missingGuid = true;
    }

    axios
      .get(process.env.VUE_APP_ADMIN_BACKEND + "zinsSetting/" + this.$route.params.guid, {
        headers: {
          "x-access-token": this.$store.getters.getJwt,
        },
      })
      .then((res) => {
        console.log(res);
        this.zinsSetting = res.data;
        this.zinsRules = this.zinsSetting.ZinsRule;
        this.modifyableActiveState = this.zinsSetting.active;

        this.$store.dispatch("newResponse", {
          message: "Zinssatz erfolgreich geladen ✔️",
          success: true,
        });
      })
      .catch((e) => {
        console.log(e);
        this.$store.dispatch("newResponse", {
          message: "Ein Fehler ist aufgetreten, die Zinsen konnten nicht geladen werden",
          success: false,
        });
        if (e.response.status == 403) {
          this.$router.push("/login");
        }
      });
  },
};
</script>

<style scoped>
[type="checkbox"]:checked {
  background-image: none !important;
}
</style>
