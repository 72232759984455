import { createRouter, createWebHistory } from 'vue-router'
import KontoEroeffnung from '@/views/KontoEroeffnung.vue'
import ConfirmationView from "@/views/ConfirmationView.vue";
import ErtragsrechnerView from "@/views/ErtragsrechnerView.vue";
import NotFound from "@/views/NotFound.vue";
import LoginView from "@/views/LoginView.vue";
import Widerrufsbelehrung from "@/views/Widerrufsbelehrung.vue";
import Kirchensteuerverfahren from "@/views/Kirchensteuerverfahren.vue";
import AdminContainer from "@/components/AdminContainer.vue";
import AdminView from "@/views/AdminView.vue";
import EasyNav from "@/components/EasyNav.vue";
import AccountView from "@/views/AccountView.vue"
import ZinsSettings from "@/views/ZinsSettings.vue";
import AccountsView from "@/views/AccountsView.vue";
import StatsFunnel from "@/views/StatsFunnel.vue";
import StatsCenter from "@/views/StatsCenter.vue";
import ZinsSetting from "@/views/ZinsSetting.vue";
import StatsUmfrage from "@/views/StatsUmfrage.vue";
const ApplicationView = () => import("@/views/ApplicationView.vue");
const zinsTable = () => import("@/views/EmbedZinsen.vue");
import ApplicationsView from "@/views/ApplicationsView.vue";
import shared from "@/shared.js";

import store from '@/store/index.js';

const routes = [
  {
    path: '/',
    component: EasyNav,
    children: [
      {
        path: '/',
        name: 'noideawheretogo',
        component: KontoEroeffnung,
      },
      {
        path: '/konto-erstellen',
        name: 'konto-erstellen',
        component: KontoEroeffnung,
      },
      {
        path: '/confirmation/:guid',
        name: 'confirmation',
        props: true,
        component: ConfirmationView
      },
      {
        path: '/ertragsrechner',
        name: 'ertragsrechner',
        component: ErtragsrechnerView
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound,
  },
  {
    path: '/widerrufsbelehrung',
    name: 'widerrufsbelehrung',
    component: Widerrufsbelehrung
  },
  {
    path: '/kirchensteuerverfahren',
    name: 'kirchensteuerverfahren',
    component: Kirchensteuerverfahren
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/zinstable',
    name: 'zinstable',
    component: zinsTable,
    meta: {
      title: "Vakifbank Zinsen"
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminContainer,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: AdminView,
        meta: {
          title: "Dashboard",
        }
      },
      {
        path: 'zinsen',
        name: 'zinsen',
        component: ZinsSettings,
        meta: {
          title: "Zinsen bearbeiten"
        }
      },
      {
        path: 'zinsen/:guid',
        name: 'zins',
        component: ZinsSetting,
        meta: {
          title: "Zinsen einstellen",
        }
      },
      {
        path: 'applications',
        name: 'applications',
        component: ApplicationsView,
        meta: {
          title: "Anträge",
        }
      },
      {
        path: 'application/:guid',
        name: 'application',
        component: ApplicationView,
        meta: {
          title: "Antrag",
        }
      },
      {
        path: 'account/:guid',
        name: 'account',
        component: AccountView,
        meta: {
          title: "Account bearbeiten",
        }
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: AccountsView,
        meta: {
          title: "Accounts verwalten",
        }
      },
      {
        path: 'stats',
        name: 'stats',
        component: StatsCenter,
        meta: {
          title: "Statistik Zentrum",
        },
      },
      {
        path: 'stats/umfrage',
        name: 'umfrage',
        component: StatsUmfrage,
        meta: {
          title: "Statistik: Umfrage Marketing",
        },
      },
      {
        path: 'stats/funnel',
        name: 'funnel',
        component: StatsFunnel,
        meta: {
          title: "Step for Step Kontoeröffnung - Funnel Statistik",
        },
      }
    ],
  }
]

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to) => {
  if (to.fullPath.includes('/admin')) {
    if (!store.getters.getJwt) {
      console.log("go to admin 1")
      router.push("/login");
    }

  }
  // logout functionality:
  if (to.fullPath.includes("logout")) {
    console.log("deleting jwt...");

    // reset store
    store.replaceState(shared.getStartingState());
    router.push("/login");
  }

  if (to.meta.title) {
    document.title = to.meta.title + " - Vakifbank";
  }
});

export default router
