<template>
  <div>
    <div class="flex justify-center">
      <div class="p-4 w-full">
        <div v-if="validGuid" :guid="guid">
          <GoToCrif
            v-if="validGuid"
            :guid="guid"
            :is-gemeinschaftskonto="isGemeinschaftskonto"
          />
          <div class="mt-12">
            <Divider />
          </div>
          <section class="flex justify-center flex-col my-4">
            <div class="max-w-md mx-auto text-center">
              <h3 class="font-bold">Optional: PDF herunterladen</h3>
              <p>
                Wenn Sie möchten können Sie
                <a :href="pdfLocation" target="__blank" class="underline"
                  >hier das generierte PDF</a
                >
                herunterladen.
              </p>
            </div>
          </section>
          <div>
            <CustomModal :show="showModal">
              <form
                class="text-center mt-9 flex justify-center flex-col"
                @submit="sendQuestions"
                @submit.prevent="onSubmit"
              >
                <fieldset :class="fieldsetClass">
                  <p class="text-sm italic">
                    Bitte wählen Sie zumindest eine der folgenden Optionen:
                  </p>
                  <legend class="font-bold">
                    Wie Sind Sie auf uns aufmerksam geworden?
                  </legend>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="bestKunde"
                      v-model="umfrage.bestehenderKunde"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="bestKunde">
                      Bestehender Kunde
                    </label>
                  </div>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="zeitung"
                      v-model="umfrage.zeitung"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="zeitung">Zeitung</label>
                  </div>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="internet"
                      v-model="umfrage.internet"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="internet"
                      >Internet / Homepage</label
                    >
                  </div>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="durchblicker"
                      v-model="umfrage.durchblicker"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="durchblicker"
                      >Durchblicker.at</label
                    >
                  </div>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="freunde"
                      v-model="umfrage.freunde"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="freunde"
                      >Freunde / Bekannte</label
                    >
                  </div>
                  <div class="flex align-middle items-center">
                    <input
                      class="checkbox"
                      id="sonstige"
                      v-model="umfrage.sonstige"
                      type="checkbox"
                    />
                    <label class="block text-sm py-3 px-2" for="sonstige"
                      >Sonstiges
                    </label>
                  </div>
                  <div v-if="umfrage.sonstige">
                    <label class="block text-sm font-bold mb-2" for="sonstiges_text"
                      >Sonstiges (Erkärung):</label
                    >
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="sonstiges_text"
                      v-model="umfrage.sonstige_txt"
                      type="text"
                      required
                      aria-required="true"
                      placeholder="Beschreibung"
                    />
                  </div>
                </fieldset>
                <div>
                  <button class="btn btn-primary">Senden</button>
                </div>
              </form>
            </CustomModal>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <LoadingCircle />
          </div>
          <div v-else>
            <h1>Leider hat das nicht geklappt...</h1>
            <p>
              Ein Fehler ist aufgetreten.
              <a href="https://www.vakifbank.at">Hier</a> kommen Sie zurück zu unserer
              Homepage.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Divider from "@/components/Divider.vue";
import GoToCrif from "@/components/GoToCrif.vue";
import CustomModal from "@/components/CustomModal.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: { GoToCrif, LoadingCircle, CustomModal, Divider },
  data() {
    return {
      guid: "",
      pdfLocation: "",
      validGuid: false,
      loading: false,
      showModal: false,
      isGemeinschaftskonto: false,
      umfrage: {
        bestehenderKunde: false,
        zeitung: false,
        internet: false,
        durchblicker: false,
        freunde: false,
        sonstige: false,
        sonstige_txt: "",
      },
      showErrorMessage: false,
    };
  },
  computed: {
    fieldsetClass() {
      let fieldsetClass = "mb-2 border p-3 rounded-lg";
      if (this.showErrorMessage && !this.fieldsetOkay) {
        fieldsetClass = fieldsetClass + " bg-red-100";
      }

      console.log(fieldsetClass);

      return fieldsetClass;
    },
    fieldsetOkay() {
      return (
        this.umfrage.bestehenderKunde ||
        this.umfrage.zeitung ||
        this.umfrage.internet ||
        this.umfrage.durchblicker ||
        this.umfrage.freunde ||
        this.umfrage.sonstige
      );
    },
  },
  async mounted() {
    await this.$router.isReady();
    this.guid = this.$route.params.guid;
    this.loadStepFiveInfo();
  },
  methods: {
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    sendQuestions() {
      if (this.fieldsetOkay) {
        axios
          .post(process.env.VUE_APP_BACKEND_DOMAIN + "questions.php", {
            bestehender_kunde: this.umfrage.bestehenderKunde,
            zeitung: this.umfrage.zeitung,
            internet: this.umfrage.internet,
            durchblicker: this.umfrage.durchblicker,
            freunde: this.umfrage.freunde,
            sonstige: this.umfrage.sonstige,
            sonstige_txt: this.umfrage.sonstige_txt,
            guid: this.guid,
          })
          .then(() => {
            this.$store.dispatch("newResponse", {
              message: "Erfolgreich abgestimmt ✔️",
              success: true,
            });
            this.showModal = false;
          });
      } else {
        this.showErrorMessage = true;
      }
    },
    loadStepFiveInfo() {
      this.loading = true;
      // load data from crif
      console.log(this.guid);
      axios
        .get(process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=5", {
          headers: {
            Processid: this.guid,
          },
        })
        .then(async (res) => {
          console.log(res);
          this.pdfLocation = process.env.VUE_APP_BACKEND_DOMAIN + res.data.pdfLocation;
          this.loading = false;
          this.validGuid = true;
          await this.Sleep(300);
          this.showModal = true;
          this.isGemeinschaftskonto = res.data.gemeinschaftskonto;
        })
        .catch((e) => {
          console.log(e);
          this.validGuid = false;
          this.loading = false;
        });
    },
  },
};
</script>
