<template>
  <div>
    <div
      class="border bg-white rounded-lg shadow mx-1 sm:mx-2 my-2 sm:my-4 px-2 hover:bg-gray-200 transition-all hover:cursor-pointer"
    >
      <router-link :to="`/admin/application/${application.guid}`">
        <ApplicationProgress :application="application" :showMark="false" />
        <div class="flex justify-start text-sm">
          <span class="pr-2">Antrag vom {{ bd(application.step1_timestamp) }}</span>
          <p v-if="application.gemeinschaftskonto">(Gemeinschaftskonto)</p>
        </div>
        <h5 class="pb-1">
          <strong>{{ application.vorname + " " + application.nachname }}</strong> (€{{
            application.betrag
          }}
          über {{ application.anlagedauer }} Monate)
        </h5>
      </router-link>
    </div>
  </div>
</template>

<script>
import ApplicationProgress from "./ApplicationProgress.vue";
import shared from "@/shared.js";

export default {
  components: {
    ApplicationProgress,
  },
  data() {
    return {};
  },
  methods: {
    bd(d) {
      return shared.beautifyDate(new Date(d), true);
    },
  },
  props: {
    application: {
      type: Object,
      default: () => {},
    },
    marked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
