<template>
  <CMBreadCrumb :children="[{ text: 'Anträge', link: '/admin/applications' }]" />
  <section class="mb-4">
    <h2 class="font-bold">Filter:</h2>
    <form @submit="requestApplications" @submit.prevent="obSubmit">
      <label
        for="default-search"
        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >Search</label
      >
      <div class="relative mb-2">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          v-model="$store.state.applicationsFilter.search"
          id="default-search"
          class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
          placeholder="Suchen"
        />
        <button
          type="submit"
          class="text-white absolute right-2.5 bottom-2.5 bg-primary focus:ring-2 ring-secondary focus:outline-none font-medium rounded-lg text-sm px-4 py-2"
        >
          Suchen
        </button>
      </div>

      <div class="text-xs sm:text-md">
        <label for="filter" class="block text-sm font-medium leading-6 text-gray-900"
          >Erreichte Schritte:</label
        >
        <div class="flex">
          <select
            id="filter_filter"
            v-model="$store.state.applicationsFilter.mode"
            class="mt-2 mr-1 block w-full rounded-md border border-gray-200 py-1.5 pl-3 pr-10 outline-primary text-gray-900 ring-inset ring-secondary focus:ring-2 focus:ring-secondary sm:text-sm sm:leading-6"
          >
            <option value="more">Mehr als</option>
            <option value="less">Weniger als</option>
            <option value="exact">Genau</option>
          </select>
          <select
            id="filter"
            v-model="$store.state.applicationsFilter.filter"
            class="mt-2 ml-1 block w-full rounded-md border border-gray-200 py-1.5 pl-3 pr-10 outline-primary text-gray-900 ring-inset ring-secondary focus:ring-2 focus:ring-secondary sm:text-sm sm:leading-6"
          >
            <option v-for="index in options.length" :key="index" :value="index">
              {{ options[index - 1] }}
            </option>
          </select>
        </div>
        <label
          for="sortMode"
          class="block text-sm mt-2 font-medium leading-6 text-gray-900"
          >Sortieren nach:</label
        >
        <div class="flex">
          <select
            id="sortType"
            v-model="$store.state.applicationsFilter.sortType"
            class="mt-2 mr-1 block w-full rounded-md border border-gray-200 py-1.5 pl-3 pr-10 outline-primary text-gray-900 ring-inset ring-secondary focus:ring-2 focus:ring-secondary sm:text-sm sm:leading-6"
          >
            <option value="date">Erstellungsdatum</option>
            <option value="step">Erreichten Schritten</option>
          </select>
          <select
            id="sortMode"
            v-model="$store.state.applicationsFilter.sortMode"
            class="mt-2 ml-1 block w-full rounded-md border border-gray-200 py-1.5 pl-3 pr-10 outline-primary text-gray-900 ring-inset ring-secondary focus:ring-2 focus:ring-secondary sm:text-sm sm:leading-6"
          >
            <option value="desc">absteigend</option>
            <option value="asc">aufsteigend</option>
          </select>
        </div>
      </div>
    </form>
  </section>
  <section>
    <LoadingCircle v-if="loading" />
    <div v-else-if="applications">
      <p class="underline mb-1">{{ applications.length }} Ergebnisse:</p>
      <div v-for="index in applications.length" :key="index">
        <Application :application="applications[index - 1]" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import shared from "@/shared.js";
import Application from "@/components/Application.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";

export default {
  components: {
    Application,
    LoadingCircle,
    CMBreadCrumb,
  },
  data() {
    return {
      applications: [],
      loading: false,
      options: [],
    };
  },
  methods: {
    requestApplications() {
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_DOMAIN +
            "api.php?step=" +
            this.$store.state.applicationsFilter.filter +
            "&search=" +
            this.$store.state.applicationsFilter.search +
            "&mode=" +
            this.$store.state.applicationsFilter.mode +
            "&sortType=" +
            this.$store.state.applicationsFilter.sortType +
            "&sortMode=" +
            this.$store.state.applicationsFilter.sortMode,
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            if (res.data[0].guid) {
              this.applications = res.data;
              this.$store.state.applications = res.data;
            }
          } else if (res.data.length == 0) {
            this.applications = res.data;
          }
          this.loading = false;
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich Anträge durchsucht",
            success: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$store.dispatch("newResponse", {
            message: e.response.data,
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
  created() {
    if (this.$store.state.applications.length == 0) {
      this.requestApplications();
    } else {
      this.applications = this.$store.state.applications;
    }

    try {
      this.options = shared.getStepsArray();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
