import { createStore } from 'vuex'
import { toRaw } from "vue";
import shared from "@/shared.js";
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from "jwt-decode";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state:
  {
    jwt: "",
    persons: [{}, {}],
    fieldsets: {},
    bankInfo: {},
    responses: [],
    applications: [],
    lastApplicationGuid: "unspecified",
    applicationsFilter: {
      filter: "1",
      search: "",
      mode: "more",
      sortMode: "desc",
      sortType: "date",
    }
  },
  getters: {
    getResponses(state) {
      return state.responses;
    },
    getJwt(state) {
      return state.jwt;
    },
    isGermanAccountCreation(state) {
      try {
        if (state.persons[0].country.toUpperCase() == 'DE') {
          return true;
        }

        return false;
      } catch (e) {
        console.log("Assuming it's not german, because of this bug:");
        console.log(e);
        return false;
      }
    },
    getJwtDecoded(state) {
      return jwt_decode(state.jwt);
    },
    getApplication: (state) => (guid) => {
      let application = shared.getObjectViaId(state.applications, guid);
      console.log(application);
      return application;
    }
  },
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    addResponse(state, response) {
      // todo: check for content of response

      state.responses.push({
        guid: response.id,
        message: response.message,
        success: Boolean(response.success),
        time: response.time
      });
    },
    removeResponse(state, guid) {
      console.log("Removing response...");
      let tempResponses = toRaw(state.responses);
      state.responses = []; // ... important: without this reset, the component won't react to removal of element!
      state.responses = shared.removeObjectWithId(tempResponses, guid);
    },
  },
  actions: {
    async newResponse({ commit }, res) {
      console.log("Text:" + res.message, res.success, res.time);
      res.id = uuidv4();

      commit("addResponse", res);
      let msSleep = 7500;
      if (res.time) {
        msSleep = Number(res.time);
      }
      await shared.sleep(msSleep);
      commit("removeResponse", res.id);
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],

})
