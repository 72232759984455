<template>
  <form @submit="sendStepFour" @submit.prevent="onSubmit">
    <FieldsetsStepFour
      v-model="fieldsets"
      @update="updateFieldsets"
      :showErrorMessage="showErrorMessage"
    />
    <p v-if="showErrorMessage">{{ errorMessage }}</p>
    <div class="flex justify-between py-3">
      <button type="button" class="btn btn-grey" @click="$emit('goBack')">Zurück</button>
      <button v-if="loading" class="btn btn-primary">Weiter <LoadingCircle /></button>
      <button v-else class="btn btn-primary">Weiter</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import FieldsetsStepFour from "./FieldsetsStepFour.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: { LoadingCircle, FieldsetsStepFour },
  props: {
    guid: {
      type: String,
    },
  },
  data() {
    return {
      // general
      loading: false,
      showErrorMessage: false,
      errorMessage: "",

      fieldsets: {
        // fieldset 0
        dienstort_inland: false,
        wohnsitzaenderung: false,
        immobilienerwerb: false,
        vermittlung: false,
        finanzplatz_austria: false,
        langfristige_veranlagung_inland: false,
        sonstige_1: false,
        sonstige_1_text: "",

        // fieldset 1
        einkommen_unselbststaendig: false,
        einkommen_freiberuf: false,
        dividende_etc: false,
        erbschaft_schenkung: false,
        verkauf_vermoegenswerte: false,
        ablauf_von_vertraegen: false,
        sonstige_2: false,
        sonstige_2_text: "",

        // fieldset 2
        lohn_gehaltszettel: false,
        einkommenssteuererklaerung: false,
        depotauszug: false,
        erbschaft: false,
        sonstige_vertraege: false,
        sonstige_3: false,
        sonstige_3_text: "",

        // fieldset 3
        lebensunterhalt: false,
        schenkung: false,
        veranlagung_finanzprodukte: false,
        immobilienerwerb_2: false,
        veranlagung_unternehmensbeteiligungen: false,
        sonstige_4: false,
        sonstige_4_text: "",

        // fieldset
        weitere_veranlagungen_geplant: false,
      },
    };
  },
  computed: {
    fieldsetsOkay() {
      let returnObject = [false, false, false];

      if (
        this.fieldsets.dienstort_inland ||
        this.fieldsets.wohnsitzaenderung ||
        this.fieldsets.immobilienerwerb ||
        this.fieldsets.vermittlung ||
        this.fieldsets.finanzplatz_austria ||
        this.fieldsets.langfristige_veranlagung_inland ||
        this.fieldsets.sonstige_1
      ) {
        returnObject[0] = true;
      }

      if (
        this.fieldsets.einkommen_unselbststaendig ||
        this.fieldsets.einkommen_freiberuf ||
        this.fieldsets.dividende_etc ||
        this.fieldsets.erbschaft_schenkung ||
        this.fieldsets.verkauf_vermoegenswerte ||
        this.fieldsets.ablauf_von_vertraegen ||
        this.fieldsets.sonstige_2
      ) {
        returnObject[1] = true;
      }

      /**
       * NOTICE: IF NEEDED TO GET "Nachweis der Einkünfte / Vermögen - Beibringen von Dokumenten (Mehrfachauswahl)" back,
       * look at the github commits before 23.03.23 :)
       */
      if (
        this.fieldsets.lebensunterhalt ||
        this.fieldsets.schenkung ||
        this.fieldsets.veranlagung_finanzprodukte ||
        this.fieldsets.immobilienerwerb_2 ||
        this.fieldsets.veranlagung_unternehmensbeteiligungen ||
        this.fieldsets.sonstige_4
      ) {
        returnObject[2] = true;
      }

      return returnObject;
    },
  },
  methods: {
    updateFieldsets(fieldsets) {
      console.log("Update Fieldsets...");
      this.fieldsets = fieldsets;
    },
    fieldsetClass(n) {
      let fieldsetClass = "mb-2 border p-3 rounded-lg";
      if (this.showErrorMessage && !this.fieldsetsOkay[n]) {
        fieldsetClass = fieldsetClass + " bg-red-100";
      }

      return fieldsetClass;
    },
    sendStepFour() {
      this.$store.state.fieldsets = this.fieldsets;

      // german requirements: [0,1]
      // austrian requirements: [0,2]

      let abort = false;

      if (this.$route.query.country == "at") {
        if (!this.fieldsetsOkay[0] || !this.fieldsetsOkay[2]) {
          console.log("Issue because of Austrian Laws");
          abort = true;
        }
      } else if (this.$route.query.country == "de") {
        if (!this.fieldsetsOkay[0] || !this.fieldsetsOkay[1]) {
          console.log("Issue because of German Laws");
          abort = true;
        }
      }

      if (abort) {
        console.log("Not okay.");
        this.showErrorMessage = true;
        this.errorMessage =
          "Bitte wählen Sie bei jedem Fieldset zumindest eine der Optionen aus.";
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.$store.state.fieldsets = this.fieldsets;
      axios
        .post(
          process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=4",
          {
            dienstort_inland: this.fieldsets.dienstort_inland,
            wohnsitzaenderung: this.fieldsets.wohnsitzaenderung,
            immobilienerwerb: this.fieldsets.immobilienerwerb,
            vermittlung: this.fieldsets.vermittlung,
            finanzplatz_austria: this.fieldsets.finanzplatz_austria,
            langfristige_veranlagung_inland: this.fieldsets
              .langfristige_veranlagung_inland,
            sonstige_1: this.fieldsets.sonstige_1,
            sonstige_1_text: this.fieldsets.sonstige_1_text,

            einkommen_unselbststaendig: this.fieldsets.einkommen_unselbststaendig,
            einkommen_freiberuf: this.fieldsets.einkommen_freiberuf,
            dividende_etc: this.fieldsets.dividende_etc,
            erbschaft_schenkung: this.fieldsets.erbschaft_schenkung,
            verkauf_vermoegenswerte: this.fieldsets.verkauf_vermoegenswerte,
            ablauf_von_vertraegen: this.fieldsets.ablauf_von_vertraegen,
            sonstige_2: this.fieldsets.sonstige_2,
            sonstige_2_text: this.fieldsets.sonstige_2_text,

            lohn_gehaltszettel: this.fieldsets.lohn_gehaltszettel,
            einkommenssteuererklaerung: this.fieldsets.einkommenssteuererklaerung,
            depotauszug: this.fieldsets.depotauszug,
            erbschaft: this.fieldsets.erbschaft,
            sonstige_vertraege: this.fieldsets.sonstige_vertraege,
            sonstige_3: this.fieldsets.sonstige_3,
            sonstige_3_text: this.fieldsets.sonstige_3_text,

            lebensunterhalt: this.fieldsets.lebensunterhalt,
            schenkung: this.fieldsets.schenkung,
            veranlagung_finanzprodukte: this.fieldsets.veranlagung_finanzprodukte,
            immobilienerwerb_2: this.fieldsets.immobilienerwerb_2,
            veranlagung_unternehmensbeteiligungen: this.fieldsets
              .veranlagung_unternehmensbeteiligungen,
            sonstige_4: this.fieldsets.sonstige_4,
            sonstige_4_text: this.fieldsets.sonstige_4_text,

            weitere_veranlagungen_geplant: this.fieldsets.weitere_veranlagungen_geplant,
          },
          {
            headers: {
              Processid: this.guid,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.loading = false;
          this.$emit("confirmation");
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
