<template>
  <div class="w-full min-h-screen bg-gray-700">
    <div class="p-6">
      <div class="bg-gray-900 text-green-300 bg-opacity-30 flex flex-col p-3 rounded shadow">
        <h1 class="font-bold py-2">404 - Seite nicht gefunden</h1>
        <a href="/" class="btn btn-primary">Zur Homepage</a>
      </div>
    </div>
  </div>
</template>
