<template>
  <div class="flex flex-col max-w-lg">
    <div class="bg-white p-6 m-2 sm:m-4 rounded shadow text-center">
      <h1 class="font-bold text-xl m-2">Konto eröffnen</h1>
      <h2 class="italic">
        Bevor Sie fortfahren, müssen sie folgende Bedingungen akzeptieren:
      </h2>
      <form @submit="confirmDatenschutz">
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmDatenschutz"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmDatenschutz" class="p-2">
            Sie akzeptieren unsere
            <a
              href="https://www.vakifbank.at/de/datenschutzerklarung/"
              class="underline"
              target="__blank"
              >Datenschutzrichtlinien</a
            >.
          </label>
        </div>
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmWiderruf"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmWiderruf" class="p-2">
            Sie akzeptieren unsere
            <a href="/widerrufsbelehrung-vakifbank.pdf" class="underline" target="__blank"
              >Widerrufsbelehrung</a
            >.
          </label>
        </div>
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmDatenschutz2"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmDatenschutz2" class="p-2">
            Hiermit erklären Sie, dass die Eröffnung des Festgeldkontos und des
            erforderlichen Abwicklungskontos auf eigene Rechnung erfolgt und Sie der/die
            wirtschaftlich Berechtigte sind. Der/Die Kontoinhaber sind
            einzelzeichnungsberechtigt.
          </label>
        </div>
        <button class="btn btn-primary m-2">
          Ich akzeptiere die Datenschutzrichtlinien
        </button>
      </form>
    </div>
    <div class="bg-white p-6 m-2 sm:m-4 rounded shadow text-center">
      <h1 class="font-bold text-xl m-2">Schritt für Schritt Anleitung</h1>
      <h2 class="italic">
        Wir haben den Prozess der Konto Erstellung so einfach wie möglich gemacht. In
        wenigen Minuten können Sie bei uns kostenfrei ein Konto erstellen.
      </h2>
      <div class="mt-3">
        <h3 class="font-bold text-lg">Schritt 1: Formular</h3>
        <p>
          Das Formular ist sehr simpel und kann in ca. 5 Minuten online ausgefüllt werden.
        </p>
      </div>
      <div class="mt-3">
        <h3 class="font-bold text-lg">Schritt 2: Identifikation</h3>
        <p>
          Nachdem Sie das Formular ausgefüllt haben, erhalten Sie eine E-Mail mit einem
          Link, mit dem Sie sich online per Videocall identifizieren können.
        </p>
      </div>
      <div class="mt-3">
        <h3 class="font-bold text-lg">Schritt 3: Fertig</h3>
        <p>
          Nach erfolgreicher Online-Video-Identifikation wird die Bank Ihren Antrag
          bearbeiten und Sie schriftlich über die erfolgreiche Kontoeröffnung informieren.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    confirmDatenschutz() {
      this.$emit("confirmation");
    },
  },
};
</script>
