<template>
  <form @submit="sendStepOne" @submit.prevent="onSubmit" v-if="!loadingStepOne">
    <h2 class="font-bold mb-4">Budget und Zeitrahmen</h2>
    <div>
      <label class="block text-sm font-bold mb-2" for="betrag">Betrag in € </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="betrag"
        v-model="betrag"
        min="5000"
        type="number"
        required
        aria-required="true"
        step="1"
        placeholder="Betrag in €"
      />
    </div>
    <div class="py-2">
      <Anlagedauer
        v-if="minMonths.length > 0"
        v-model="anlageDauerMonate"
        :options="minMonths"
      />
    </div>
    <div class="flex justify-end py-3">
      <div v-if="isReady || defIsReady">
        <button v-if="loading" class="btn btn-primary" aria-hidden="true">
          Weiter
          <LoadingCircle />
        </button>
        <button v-else class="btn btn-primary" aria-hidden="true">Weiter</button>
      </div>
      <button v-else class="btn btn-disabled" aria-hidden="true" disabled="true">
        Weiter
      </button>
    </div>
    <span v-if="errorMsg">{{ errorMsg }}</span>
  </form>
  <LoadingCircle v-else />
</template>

<script>
import axios from "axios";
import Anlagedauer from "@/components/Anlagedauer.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: {
    Anlagedauer,
    LoadingCircle,
  },
  data() {
    return {
      betrag: 0,
      anlageDauerMonate: 0,
      loading: false,
      loadingStepOne: true,
      errorMsg: "",
      defIsReady: false,
      minMonths: [],
    };
  },
  watch: {
    anlageDauerMonate(newADM, oldADM) {
      if (newADM.isNaN) {
        this.anlageDauerMonate = Number(oldADM);
      }
    },
  },
  computed: {
    isReady() {
      if (this.anlageDauerMonate != 0 && this.betrag != 0) {
        return true;
      }

      return false;
    },
  },
  async created() {
    if (this.$route.query.betrag) {
      this.betrag = Number(this.$route.query.betrag);
    }

    if (this.$route.query.anlagedauer) {
      this.anlageDauerMonate = Number(this.$route.query.anlagedauer);
    }

    if (this.$route.query.betrag && this.$route.query.anlageDauerMonate) {
      this.defIsReady = true;
    }

    if (this.$route.query.country == "") {
      this.$store.dispatch("ne  Response", {
        message: "Fehlende Land Information",
        success: false,
      });
    }

    // load "options" (of months)
    axios
      .get(
        process.env.VUE_APP_ADMIN_BACKEND +
          `zinsRules?country=${this.$route.query.country}&mode=festgeld`
      )
      .then((res) => {
        console.log(res);
        res.data.zinsRules.forEach((zR) => {
          this.minMonths.push(zR.minMonths);
        });
        this.anlageDauerMonate = Number(res.data.zinsRules[0].minMonths);
        this.loadingStepOne = false;
      })
      .catch((e) => {
        console.log(e);
        this.$store.dispatch("newResponse", {
          message: e,
          success: false,
        });
        this.loadingStepOne = false;
      });
  },
  methods: {
    sendStepOne() {
      this.loading = true;
      axios
        .post(process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=1", {
          betrag: this.betrag,
          anlagedauer: this.anlageDauerMonate,
        })
        .then((res) => {
          this.loading = false;

          // emit the guid at the confirmation event
          this.$emit("confirmation", res.data.guid);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
