<template>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="empfName">Empfängername</label>
    <input
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="empfName"
      v-model="data.name"
      type="text"
      required
      :disabled="disabled"
      aria-required="true"
      placeholder="Max Mustermann"
    />
  </div>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="empfIban">IBAN</label>
    <input
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="empfIban"
      v-model="data.iban"
      type="text"
      :disabled="disabled"
      required
      aria-required="true"
      placeholder="IBAN"
    />
  </div>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="empfBIC">BIC</label>
    <input
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="empfBIC"
      v-model="data.bic"
      :disabled="disabled"
      type="text"
      required
      aria-required="true"
      placeholder="BIC"
    />
  </div>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="empfBank">Bank</label>
    <input
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="empfBank"
      v-model="data.bank"
      type="text"
      :disabled="disabled"
      required
      aria-required="true"
      placeholder="Bank"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        name: "",
        iban: "",
        bic: "",
        bank: "",
      },
    };
  },
  props: {
    proxyData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.proxyData) {
      this.data = this.proxyData;
    }
  },
  watch: {
    data: {
      handler(newData) {
        if (newData.name == "test") {
          this.data.name = "Test Test";
          if (this.$route.query.country == "de") {
            this.data.iban = "DE02120300000000202051";
          } else {
            this.data.iban = "AT026000000001349870";
          }
          this.data.bic = "12345";
          this.data.bank = "Test Bank";
        }
        this.$emit("update", this.data);
      },
      deep: true,
    },
  },
};
</script>
