<template>
  <section v-if="countryMissing">
    <div class="text-center">
      <h1 class="text-xl font-bold mb-3">Vakifbank Ertragsrechner</h1>
      <p>Für welches Land wollen Sie den Ertragsrechner nutzen?</p>
      <router-link v-if="austriaAvailable" to="/ertragsrechner?country=at">
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          Österreich 🇦🇹
        </div>
      </router-link>
      <router-link v-if="germanyAvailable" to="/ertragsrechner?country=de">
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          Deutschland 🇩🇪
        </div>
      </router-link>
    </div>
  </section>
  <section v-else-if="modeMissing">
    <div class="text-center">
      <h1 class="text-xl font-bold mb-3">
        Vakifbank Ertragsrechner {{ $route.query.country == "at" ? "🇦🇹" : "🇩🇪" }}
      </h1>
      <p>Für welche Kontoart wollen Sie den Ertragsrechner nutzen?</p>
      <router-link
        v-if="festGeldAvailable"
        :to="`/ertragsrechner?country=${$route.query.country}&mode=festgeld`"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          Festgeld
        </div>
      </router-link>
      <router-link
        v-if="sparbuchAvailable"
        :to="`/ertragsrechner?country=${$route.query.country}&mode=sparbuch`"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          Sparbuch
        </div>
      </router-link>
    </div>
  </section>
  <section v-else-if="modeAndCountryOkay || $route.query.specificID">
    <form @submit.prevent="onSubmit">
      <h2 class="font-bold text-2xl mb-4 flex" v-if="!$route.query.specificID">
        <div v-if="$route.query.mode == 'festgeld'">Festgeld Ertragsrechner</div>
        <div v-else-if="$route.query.mode == 'sparbuch'">Sparbuch Ertragsrechner</div>
        <div class="ml-2">
          {{ $route.query.country == "at" ? "🇦🇹" : "🇩🇪" }}
        </div>
      </h2>
      <h2 v-else class="bg-vakifbank text-xl text-white font-bold p-2 rounded mb-2">
        Ertragsrechner Test-Modus!
      </h2>
      <p v-if="!$route.query.specificID">
        Mit dem Ertragsrechner berechnen Sie schnell und unverbindlich, was Sie sich an
        Zinsen erwarten können. Verwenden Sie den Schieberegler zur Einstellung der
        Laufzeit und geben Sie die zu berechnende Geldmenge unverbindlich an.
      </p>
      <div v-if="festGeldAvailable && sparbuchAvailable">
        <label class="block text-sm font-bold my-2" for="mode">Anlage Modus:</label>
        <select
          id="mode"
          class="select w-full shadow border border-gray-200"
          :class="$route.query.specificID ? 'disabled' : ''"
          aria-required="true"
          v-model="mode"
          :disabled="$route.query.specificID ? true : false"
          @change="
            $router.push(`/ertragsrechner?country=${$route.query.country}&mode=${mode}`)
          "
        >
          <option disabled selected>Bitte auswählen:</option>
          <option
            value="festgeld"
            v-if="festGeldAvailable"
            :selected="$route.query.mode == 'festgeld' ? true : false"
          >
            Festgeld
          </option>
          <option
            value="sparbuch"
            v-if="sparbuchAvailable"
            :selected="$route.query.mode == 'sparbuch' ? true : false"
          >
            Sparbuch
          </option>
        </select>
      </div>
      <div>
        <label class="block text-sm font-bold my-2" for="betrag">Betrag in € </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="betrag"
          v-model="betrag"
          min="5000"
          type="number"
          required
          aria-required="true"
          step="1"
          placeholder="Betrag in €"
        />
      </div>
      <div class="py-2">
        <Anlagedauer
          v-if="minMonths.length > 0"
          v-model="anlageDauerMonate"
          :options="minMonths"
        />
      </div>
      <div>
        <p class="font-bold text-lg">Verzinsung: {{ zinsen }}% p.a.</p>
        <p>Gesamtertrag in Euro nach {{ anlageDauerMonate }} Monaten:</p>
        <p class="font-bold text-lg">{{ number_format(verzinsterBetrag) }} Euro</p>
        <p class="text-sm">Gesetzliche KESt wird einbehalten</p>
      </div>
      <div
        class="flex justify-end py-3"
        v-if="(mode == 'festgeld' && !$route.query.specificID) || queryMode == 'festgeld'"
      >
        <a
          :href="`/?betrag=${betrag}&anlagedauer=${anlageDauerMonate}&country=${$route.query.country}`"
          target="__blank"
          class="btn btn-accent text-white"
          >Festgeld Konto eröffnen</a
        >
      </div>
    </form>
  </section>
  <section class="text-center" v-else>
    <h3 class="text-xl font-bold">Leider nicht geklappt</h3>
    <p>
      Leider hat das nicht funktioniert... Das kann viele Gründe haben, versuchen Sie es
      am besten später erneut. Danke für Ihr Verständnis.
    </p>
  </section>
</template>

<script>
import axios from "axios";
import Anlagedauer from "@/components/Anlagedauer.vue";

export default {
  components: {
    Anlagedauer,
  },
  data() {
    return {
      anlageDauerMonate: 6,
      zinsen: 0,
      betrag: 5000,
      mode: "",
      country: undefined,
      zinsModi: [],
      minMonths: [], // used for anlagedauer options
    };
  },
  watch: {
    anlageDauerMonate() {
      this.updateZinsen();
    },
    mode(newMode) {
      if (newMode == "festgeld") {
        return this.calculateFestgeld();
      } else if (newMode == "sparbuch") {
        return this.calculateSparbuch();
      }
    },
    queryCountry() {
      this.loadValidOptions();
    },
    queryMode() {
      this.loadValidOptions();
    },
  },
  computed: {
    queryCountry() {
      return this.$route.query.country;
    },
    queryMode() {
      return this.$route.query.mode;
    },
    austriaAvailable() {
      let match = false;
      this.zinsModi.forEach((zinsModi) => {
        console.log(zinsModi);
        if (zinsModi.country == "at") {
          match = true;
        }
      });

      return match;
    },
    germanyAvailable() {
      let match = false;
      this.zinsModi.forEach((zinsModi) => {
        if (zinsModi.country == "de") {
          match = true;
        }
      });

      return match;
    },
    festGeldAvailable() {
      let match = false;
      this.zinsModi.forEach((zinsModi) => {
        if (
          zinsModi.country == this.$route.query.country &&
          zinsModi.mode == "festgeld"
        ) {
          match = true;
        }
      });

      return match;
    },
    sparbuchAvailable() {
      let match = false;
      this.zinsModi.forEach((zinsModi) => {
        if (
          zinsModi.country == this.$route.query.country &&
          zinsModi.mode == "sparbuch"
        ) {
          match = true;
        }
      });

      return match;
    },
    verzinsterBetrag() {
      if (this.$route.query.mode == "festgeld") {
        return this.calculateFestgeld();
      } else if (this.$route.query.mode == "sparbuch") {
        return this.calculateSparbuch();
      }
      return 0;
    },
    countryMissing() {
      const validCountries = ["at", "de"];
      if (!this.$route.query.specificID) {
        if (
          !this.$route.query.country ||
          !validCountries.includes(this.$route.query.country.toLowerCase())
        ) {
          // missing country info
          return true;
        }
      }

      return false;
    },
    modeMissing() {
      if (this.$route.query.mode == "festgeld") {
        return false;
      } else if (this.$route.query.mode == "sparbuch") {
        return false;
      } else {
        return true;
      }
    },
    modeSetViaGet() {
      if (this.$route.query.mode == "festgeld" || this.$route.query.mode == "sparbuch") {
        return true;
      }

      return false;
    },
    modeAndCountryOkay() {
      if (this.$route.query.mode == "festgeld") {
        return this.festGeldAvailable;
      } else if (this.$route.query.mode == "sparbuch") {
        return this.sparbuchAvailable;
      }

      return false;
    },
  },
  created() {
    if (this.$route.query.specificID) {
      this.mode = this.$route.query.mode;
    }

    if (this.$route.query.mode == "festgeld") {
      this.mode = "festgeld";
    } else if (this.$route.query.mode == "sparbuch") {
      this.mode = "sparbuch";
    }

    this.loadValidOptions(); // für die monatsauswahl

    // load options
    axios.get(process.env.VUE_APP_ADMIN_BACKEND + "zinsModi").then((res) => {
      this.zinsModi = res.data;
    });

    if (!this.countryMissing && !this.modeMissing) {
      this.updateZinsen();
    }
  },
  methods: {
    loadValidOptions() {
      let request_endpoint;
      if (!this.$route.query.specificID) {
        request_endpoint = `zinsRules?mode=${this.$route.query.mode}&country=${this.$route.query.country}`;
      } else {
        request_endpoint = `zinsRules?specificID=${this.$route.query.specificID}`;
      }
      axios.get(process.env.VUE_APP_ADMIN_BACKEND + request_endpoint).then((res) => {
        res.data.zinsRules.forEach((zR) => {
          console.log("HEEE", zR);
          this.minMonths.push(zR.minMonths);
        });
        this.anlageDauerMonate = Number(res.data.zinsRules[0].minMonths);
      });
    },
    updateZinsen() {
      let request_endpoint;
      if (!this.$route.query.specificID) {
        request_endpoint = `zinsBerechnung?mode=${this.$route.query.mode}&anlageDauer=${this.anlageDauerMonate}&country=${this.$route.query.country}`;
      } else {
        request_endpoint = `zinsBerechnung?specificID=${this.$route.query.specificID}&anlageDauer=${this.anlageDauerMonate}`;
      }

      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + request_endpoint)
        .then((res) => {
          this.zinsen = Number(res.data.zinsen);
        })
        .catch((e) => {
          this.zinsen = 0;
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Mit diesen Informationen können wir keine Antwort geben",
            success: false,
          });
        });
    },
    calculateFestgeld() {
      const schnittTageProMonat = 30; // eigentlich 30,438125 bei 400 jahren berechnung
      const anlageInTagen = Math.floor(this.anlageDauerMonate * schnittTageProMonat);
      console.log("Calculating Festgeld... Anlagedauer in Tagen: " + anlageInTagen);
      const zinsbetrag = (this.betrag * this.zinsen * anlageInTagen) / 36000;

      console.log("Zinsbetrag: " + zinsbetrag);
      return this.betrag + zinsbetrag;
    },
    calculateSparbuch() {
      let zinsdings = 1 + Number(this.zinsen / 100);
      let hochdings = this.anlageDauerMonate / 12;
      let mathPowResult = zinsdings ** hochdings;
      let result = this.betrag * mathPowResult;
      console.log(
        `f(x) = ${
          this.betrag +
          " x (1 + " +
          this.zinsen / 100 +
          ") ^ (" +
          this.anlageDauerMonate +
          "/12)"
        } = ${result}`
      );
      return result;
    },
    number_format(number, decimals, dec_point, thousands_sep) {
      let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "." : thousands_sep,
        dec = typeof dec_point === "undefined" ? "," : dec_point,
        toFixedFix = function (n, prec) {
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          let k = Math.pow(10, prec);
          return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
};
</script>
