<template>
  <form @submit="sendStepThree" @submit.prevent="onSubmit">
    <h2 class="font-bold mb-4">Referenzkonto</h2>
    <StepThreeForm @update="updateBankInfo" />
    <div class="flex justify-between py-3">
      <button type="button" class="btn btn-grey" @click="$emit('goBack')">Zurück</button>
      <button v-if="loading" class="btn btn-primary">Weiter <LoadingCircle /></button>
      <button v-else class="btn btn-primary">Weiter</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import LoadingCircle from "@/components/LoadingCircle.vue";
import StepThreeForm from "./StepThreeForm.vue";

export default {
  components: { LoadingCircle, StepThreeForm },
  props: {
    guid: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      bankInfo: {
        name: "",
        iban: "",
        bic: "",
        bank: "",
      },
    };
  },

  methods: {
    updateBankInfo(newInfo) {
      this.bankInfo = newInfo;
    },
    sendStepThree() {
      this.$store.state.bankInfo = this.bankInfo;
      if (!this.isValidIBANNumber(this.bankInfo.iban)) {
        alert(
          "Der IBAN scheint inkorrekt. Bitte überprüfen Sie ihre Eingabe. Wenn ihr IBAN sicher korrekt ist, und Sie diese Nachricht sehen, bitte kontaktieren Sie unsere Filiale per E-Mail oder Telefon."
        );
        return;
      }

      if (this.$route.query.country == "de") {
        if (this.bankInfo.iban.toLowerCase().substring(0,2) != this.$route.query.country) {
          alert("Bitte geben Sie einen deutschen IBAN an.");
          return;
        }
      }

      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=3",
          {
            name: this.bankInfo.name,
            iban: this.bankInfo.iban,
            bic: this.bankInfo.bic,
            bank: this.bankInfo.bank,
          },
          {
            headers: {
              Processid: this.guid,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.loading = false;
          this.$emit("confirmation");
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    isValidIBANNumber(input) {
      var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        CR: 22,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
      };
      var iban = String(input)
          .toUpperCase()
          .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
      });
      // final check
      return this.mod97(digits) === 1;
    },

    mod97(string) {
      var checksum = string.slice(0, 2),
        fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    },
  },
};
</script>
