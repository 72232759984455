<template>
  <form @submit="sendStepTwo" @submit.prevent="onSubmit">
    <h2 class="font-bold mb-4">Persönliche Angaben</h2>
    <StepTwoForm id="1" :lastSubmitTime="submitTime" @update="updatePerson1" />
    <div class="flex align-middle items-center">
      <input
        class="checkbox"
        id="check420"
        v-model="createAccountTogether"
        type="checkbox"
      />
      <label class="block text-sm p-4" for="check420"
        >Ich möchte dieses Konto als Gemeinschaftskonto erstellen.</label
      >
    </div>
    <div v-if="createAccountTogether">
      <h2 class="font-bold mb-4">
        Persönliche Angaben für zweite Person (Gemeinschaftskonto)
      </h2>
      <StepTwoForm id="2" :lastSubmitTime="submitTime" @update="updatePerson2" />
    </div>
    <span v-if="!noErrors" class="text-red-400">{{ errorMsg }}</span>
    <div class="flex justify-between py-3">
      <button type="button" class="btn btn-grey" @click="$emit('goBack')">Zurück</button>
      <button v-if="loading" class="btn btn-primary">Weiter <LoadingCircle /></button>
      <button v-else class="btn btn-primary">Weiter</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import StepTwoForm from "@/components/StepTwoForm.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: {
    StepTwoForm,
    LoadingCircle,
  },
  data() {
    return {
      createAccountTogether: false,
      submitTime: new Date(),
      loading: false,
      person1: {},
      person2: {},
      noErrors: true,
      errorMsg: "",
      p1_finished: false,
      p2_finished: false,
    };
  },
  props: {
    guid: {
      type: String,
    },
  },
  methods: {
    updatePerson1(p1) {
      this.$store.state.persons[0] = p1;
      this.person1 = p1;
    },
    updatePerson2(p2) {
      this.$store.state.persons[1] = p2;
      this.person2 = p2;
    },
    async sendStepTwo() {
      // todo on next update: wait for both responses before continuing
      console.log(this.guid);
      this.loading = true;
      this.noErrors = true;

      let axiosData1 = this.prepareData(this.person1);
      this.submitData(axiosData1)
        .then((res) => {
          console.log(res);
          if (!this.createAccountTogether) {
            this.loading = false;
            this.$emit("confirmation");
            return;
          }
          // we need to make two requests..
          let axiosData2 = this.prepareData(this.person2);
          Object.assign(axiosData2, { person_2: true });
          this.submitData(axiosData2)
            .then((res) => {
              console.log(res);
              this.loading = false;
              this.$emit("confirmation");
            })
            .catch((e) => {
              console.log(e);
              this.noErrors = false;
              this.errorMsg = e.response.data;
              this.$store.dispatch("newResponse", {
                message: "Fehler bei Person 2",
                success: false,
              });
            });
        })
        .catch((e) => {
          console.log(e);
          this.errorMsg = e.response.data;
          this.noErrors = false;
          this.$store.dispatch("newResponse", {
            message: "Fehler bei Person 1",
            success: false,
          });
        });
    },
    prepareData(personObj) {
      return {
        anrede: personObj.sex,
        vorname: personObj.firstName,
        nachname: personObj.lastName,
        plz: personObj.plz,
        ort: personObj.city,
        strasse: personObj.street,
        hausnummer: personObj.streetNo,
        land: personObj.country,
        us_citizen: personObj.us_citizen,
        us_citizen_rule: personObj.us_citizen_rule,
        extra_rule_austria: personObj.extra_rule_austria,
        extra_rule_austria_two: personObj.zweitwohnsitz,
        birthday: personObj.birthday,
        geburtsort: personObj.birthCity,
        nationality: personObj.nationality,
        telnr: personObj.telephone,
        familienstand: personObj.familienStand,
        email: personObj.email,
        beschaeftigung: personObj.beschaeftigung,
        lichtbildausweis: personObj.ausweisTyp,
        lichtbild_nummer: personObj.ausweisNummer,
        titel: personObj.title,
        agency: personObj.ausweisAgency,
        datum: personObj.ausweisDatum,
        pep: personObj.pep,
        pep_text: personObj.pep_text,
        steuerpflichtigAusland: personObj.steuerpflichtigAusland, // germany only
        steuerInfoAusland: personObj.steuerInfo, // germany only
        steuernummer: personObj.steuernummer, // germany only
        crs_accepted: personObj.crs_ok, // germany only
        kirchensteuerverfahren_accepted: personObj.kirchensteuerverfahren_ok, // germany only
      };
    },
    /**
     * Returns true or false depending on success of request
     */
    submitData(data) {
      return axios.post(
        process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=2",
        data,
        {
          headers: {
            Processid: this.guid,
          },
        }
      );
    },
  },
};
</script>
