<template>
  <div v-if="$route.query.country == 'de' || $route.query.country == 'at'">
    <KontoErstellenProcess />
  </div>
  <div v-else class="text-center">
    <h1 class="text-xl font-bold mb-3">Vakifbank Konto Eröffnen</h1>
    <p>Für welches Land wollen Sie ein Konto eröffnen?</p>
    <router-link to="/konto-erstellen?country=at">
      <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
        Österreich 🇦🇹
      </div>
    </router-link>
    <router-link to="/konto-erstellen?country=de">
      <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
        Deutschland 🇩🇪
      </div>
    </router-link>
  </div>
</template>

<script>
import KontoErstellenProcess from "@/components/KontoErstellenProcess.vue";
import axios from "axios";

export default {
  components: {
    KontoErstellenProcess,
  },
  data() {
    return {
      zinsModi: [],
      austriaAvailable: false,
      germanyAvailable: false,
    };
  },
  created() {
    axios.get(process.env.VUE_APP_ADMIN_BACKEND + "zinsModi").then((res) => {
      this.zinsModi = res.data;
      console.log(this.zinsModi);

      this.zinsModi.forEach((zinsModi) => {
        if (zinsModi.country == "de" && zinsModi.mode == "festgeld") {
          this.germanyAvailable = true;
        }

        if (zinsModi.country == "at" && zinsModi.mode == "festgeld") {
          this.austriaAvailable = true;
        }
      });

      if (!this.austriaAvailable && this.germanyAvailable) {
        this.$router.push("/konto-erstellen?country=de");
      } else if (!this.germanyAvailable && this.austriaAvailable) {
        this.$router.push("/konto-erstellen?country=at");
      }
    });
  },
};
</script>
