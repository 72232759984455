<template>
  <div :class="classList" @click="changeCountry(value)">
    {{ name }} {{ currentlyActive ? " ✔️" : "" }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
  },
  data() {
    return {
      proxyMV: "",
    };
  },
  watch: {
    modelValue(nMV) {
      console.log("Modelvalue Changed:", nMV);
      this.proxyMV = nMV;
    },
  },
  created() {
    this.proxyMV = this.modelValue;
  },
  methods: {
    changeCountry(value) {
      if (value != this.proxyMV) {
        this.proxyMV = value;
      } else {
        this.proxyMV = "";
      }

      this.$emit("update:modelValue", this.proxyMV);
    },
  },
  computed: {
    currentlyActive() {
      return this.proxyMV == this.value;
    },
    classList() {
      const base =
        "px-4 py-3 m-1 rounded-lg shadow border text-xs sm:text-normal hover:bg-gray-300 bg-opacity-50 hover:scale-95 cursor-pointer flex-1";
      if (this.currentlyActive) {
        return base + " bg-gray-500";
      }

      return base;
    },
  },
};
</script>
